import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  minimize,
  selectEndChatDialog,
  selectRankDialog,
  selectSoundOn,
  setShowEndChatDialog,
  setShowRankDialog,
  toggleSound
} from "../features/widget/uiSlice";
import {
  selectAgentLeftChat,
  selectIsChatting
} from "../features/widget/widgetSlice";
import { EMAIL_REGEX, endChat, sendEmailTranscript } from "../lib/zdChat";
import "../styles/components/ActionBar.scss";
import ChatRating from "./ChatRating";
import FontSizeSelector from "./FontSizeSelector";

const OptionsMenu = () => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [showTranscriptConfirmation, setShowTranscriptConfirmation] = useState(
    false
  );
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const isSoundOn = useSelector(selectSoundOn);
  const isChatting = useSelector(selectIsChatting);
  const isEndChatDialogVisible = useSelector(selectEndChatDialog);
  const showRankDialog = useSelector(selectRankDialog);
  const chatFinished = useSelector(selectAgentLeftChat);
  const dispatch = useDispatch();

  const doEndChat = (e) => {
    endChat()
      .then(() => {
        if (e && e.target) {
          e.target.disabled = true;
        }
      })
      .catch((e) => {
        console.log("ERROR: ", e.message);
      })
      .finally(() => {
        dispatch(setShowRankDialog({ show: false, chatFinished: true }));
        dispatch(minimize(true));
      });
  };

  const useOutsideOptionsDetect = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOptionsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const option = (icon, name, onClick) => (
    <li className={`option ${icon ? icon : null}`}>
      <button onClick={onClick}>{name}</button>
    </li>
  );

  const soundStatus = isSoundOn ? "ON" : "OFF";

  const Options = () => {
    const clickRef = useRef(null);
    useOutsideOptionsDetect(clickRef);
    return (
      optionsOpen && (
        <ul ref={clickRef} className="options">
          {option("chat-sound-on", `Sound (currently ${soundStatus})`, () =>
            dispatch(toggleSound())
          )}
          {option("chat-export", "Email Transcript", () => {
            setShowTranscript(true);
            setOptionsOpen(false);
          }
          )}
          {option("chat-close", "End Chat", () => {
            dispatch(setShowRankDialog({ show: true, chatFinished }));
            setOptionsOpen(false);
          })}
        </ul>
      )
    );
  };

  const endChatDialog = () =>
    isEndChatDialogVisible && (
      <div className="endchat-modal">
        <div className="endchat-modal-header">
          <p className="endchat-modal-title">Are you sure you want to end this chat?</p>
        </div>
        <div className="endchat-modal-footer">
          <button
            className="endchat-modal-return-btn"
            onClick={() => {
              dispatch(setShowEndChatDialog(false));
              setOptionsOpen(false);
            }}
          >
            Return to chat
          </button>
          <button
            className="endchat-modal-endchat-btn"
            onClick={() => {
              dispatch(setShowRankDialog({ chatFinished: true, show: true }));
            }}
          >
            End Chat
          </button>
        </div>
      </div>
    );

  const rateChatDialog = () =>
    showRankDialog && <ChatRating onClose={doEndChat} />;

  const sendTranscript = (email) => {
    sendEmailTranscript(email)
      .then(() => {
        setShowEmailSuccess(true);
        setOptionsOpen(false);
        setTimeout(() => setShowEmailSuccess(false), 2500);
      })
      .catch(() => {
        // TODO
        console.log("Error sending transcript");
      });
  };

  const TranscriptDialog = () => {
    const [email, setEmail] = useState("");

    const handleSendTranscript = (e) => {
      if (isChatting) {
        setShowTranscript(false);
        setOptionsOpen(false);
        setShowTranscriptConfirmation(true);
        return;
      }
      sendTranscript(email);
    };

    if (showTranscript) {
      return (
        <div className="sendtranscript-modal">
          <div className="sendtranscript-modal-header">
            <p className="title chat-export">Export Chat</p>
            <button
              aria-label="Close modal"
              onClick={() => {
                setShowTranscript(false);
              }}
            >
              Close
            </button>
          </div>
          <div className="sendtranscript-modal-body">
            <input
              className="chat-input"
              value={email}
              type="email"
              pattern={`${EMAIL_REGEX.source}`}
              placeholder="Type your email address here..."
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="sendtranscript-modal-footer">
            {showEmailSuccess && (
              <p className="email-status">The email was successfully sent!</p>
            )}
            <button
              className="sendtranscript-modal-send-btn"
              disabled={!EMAIL_REGEX.test(email)}
              onClick={(e) => handleSendTranscript(e)}
            >
              Send
            </button>
          </div>
        </div>
      );
    }

    if (showTranscriptConfirmation) {
      return (
        <div className="transcriptconfirmation-modal">
          <p className="title">
            Email will be sent to {email} when the chat ends.
          </p>
          <button
            className="dismiss-btn"
            onClick={() => {
              setShowTranscriptConfirmation(false);
            }}
          >
            OK
          </button>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="options-menu">
        <Options />
        {endChatDialog()}
        {rateChatDialog()}
        <TranscriptDialog />
        <button
          className="hp-options-button"
          aria-label="overflow-stroke"
          onClick={() => setOptionsOpen(!optionsOpen)}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            focusable="false"
            viewBox="0 0 16 16"
          >
            <g fill="currentColor">
              <circle cx="2.5" cy="8" r="1.5" />
              <circle cx="8" cy="8" r="1.5" />
              <circle cx="13.5" cy="8" r="1.5" />
            </g>
          </svg>
        </button>
      </div>
    </>
  );
};

const ChatSound = () => {
  const isSoundOn = useSelector(selectSoundOn);
  const soundIcon = isSoundOn ? "chat-sound-on" : "chat-sound-off";
  const dispatch = useDispatch();

  const handleToggleSound = () => {
    dispatch(toggleSound());
  };

  return (
    <div className="action-sound-container action-separator-both">
      <button
        className={`btn-icon text-hide ${soundIcon}`}
        title="Toggle Sound"
        onClick={() => handleToggleSound()}
      >
        Toggle Sound
      </button>
    </div>
  );
};

const ActionBar = () => (
  <div className="action-bar-container">
    <div className="action-buttons-container">
      <FontSizeSelector />
      <ChatSound />
    </div>
    <OptionsMenu />
  </div>
);

export default ActionBar;
