import { configureStore } from '@reduxjs/toolkit';
import uiReducer from '../features/widget/uiSlice';
import widgetReducer from '../features/widget/widgetSlice';

export const store = configureStore({
  reducer: {
    widget: widgetReducer,
    ui: uiReducer,
  },
});
