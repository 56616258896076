import React, { useRef } from "react";
import PropTypes from "prop-types";
import ActionBar from "./ActionBar";
import ChatInput from "./ChatInput";
import Button from "./Button";
import "../styles/components/InputBox.scss"

const InputBox = ({ addClass, onSubmit, onChange }) => {
  const inputRef = useRef(null);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(inputRef.current.value);
    inputRef.current.value = "";
  };

  return (
    <div className={`input-container ${addClass}`}>
      <form className="input-form" onSubmit={handleOnSubmit}>
        <ChatInput inputRef={inputRef} onChange={onChange} />
        <Button className="input-button" iconClass="icon-send" label="Send" />
      </form>
      <ActionBar />
    </div>
  );
};

InputBox.defaultProps = {
  addClass: "",
  onSubmit: () => {},
  onChange: () => {},
};

InputBox.propTypes = {
  addClass: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
};

export default InputBox;
