import React from "react";
import PropTypes from "prop-types";
import ChatImage from "./ChatImage";

const ChatMedia = ({ message }) => {
  const renderMediaByType = () => {
    switch (true) {
      case /^image/.test(message.attachment.mime_type):
        return <ChatImage message={message} />;
      default:
        return (
          <a href={message.attachment.url} target="_blank" rel="noreferrer">
            <div className="chat-msg">
              ⬇️ Download {message.attachment.name}
            </div>
          </a>
        );
    }
  };

  return (
    <div className="chat-media-container">
      {renderMediaByType()}
    </div>
  );

};

ChatMedia.propTypes = {
  message: PropTypes.object.isRequired,
};

export default ChatMedia;
