import React from "react";
import { useSelector } from "react-redux";
import { selectQueuePosition } from "../features/widget/widgetSlice";

const QueuePosition = () =>
  {
    const position = useSelector(selectQueuePosition);
    return position > 0 && (
      <div className="system-msg-container">
        <span className="system-msg">Queue position: {position}</span>
      </div>
    );
  };

export default QueuePosition;
