import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  minimize,
  selectFontSize,
  selectMinimized,
  selectTheme,
  setTheme
} from "../features/widget/uiSlice";
import {
  chatFinished,
  selectAccountStatus,
  selectAgentLeftChat,
  selectAgentName,
  selectConnection,
  selectIsChatting,
  selectOffline,
  visitorMessageError,
  visitorSendMessage
} from "../features/widget/widgetSlice";
import {
  endChat, initChatSDK,
  markAsRead,
  reconnect,
  sendChatMsg,
  sendTyping
} from "../lib/zdChat";
import '../styles/components/Widget.scss';
import "../styles/Widget.scss";
import Audio from "./Audio";
import ChatButton from "./chat-button/ChatButton";
import InputBox from "./InputBox";
import MessageList from "./MessageList";
import StatusContainer from "./StatusContainer";

const Widget = ({
  initialTheme,
  buttonStyle,
  departmentName,
  tags,
  showUndockButton,
  accountKey,
  staticUrl,
}) => {
  const dispatch = useDispatch();
  const [typing, setIsTyping] = useState(false);
  const isChatting = useSelector(selectIsChatting);
  const offline = useSelector(selectOffline);
  const connection = useSelector(selectConnection);
  const accountStatus = useSelector(selectAccountStatus);
  const minimized = useSelector(selectMinimized);
  const fontSize = useSelector(selectFontSize);
  const selectedTheme = useSelector(selectTheme);
  const agentName = useSelector(selectAgentName);
  const agentLeftChat = useSelector(selectAgentLeftChat);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    initChatSDK({ accountKey, dispatch, departmentName, tags });
    dispatch(setTheme(initialTheme));
  }, [dispatch, accountKey, departmentName, tags, initialTheme]);

  function syncHeight() {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
  }

  useEffect(() => {
    syncHeight();
    window.addEventListener('resize', syncHeight);
  });

  const hide = () => {
    dispatch(minimize(true));
    document.documentElement.classList.remove('wp-widget-no-scroll');
    window.scrollTo(0, scrollY);

    if(isChatting && agentLeftChat) {
      endChat();
      dispatch(chatFinished());
    }
  };

  const show = () => {
    reconnect(connection);
    setScrollY(window.scrollY);
    document.documentElement.classList.add('wp-widget-no-scroll');
    dispatch(minimize(false));
  };

  const visibilityClass = minimized ? 'chat-inactive' : 'chat-active';

  const doMarkAsRead = useCallback(() => {
    const debounced = debounce(markAsRead, 1000, { leading: true });
    return debounced();
  }, []);

  if (!isChatting && accountStatus !== "online") return null;

  const stopTyping = debounce(() => {
    if (!typing) return;
    sendTyping(false);
    setIsTyping(false);
  }, 1000);

  const handleOnSubmit = async (msg) => {
    if (!msg || offline) return;

    // Immediately stop typing
    stopTyping.flush();

    sendChatMsg(msg)
      .then(() => {
        dispatch(visitorSendMessage(msg));
      })
      .catch((err) => {
        dispatch(visitorMessageError(msg));
      });
  };

  const handleOnChange = () => {
    if (!typing) {
      sendTyping(true);
      setIsTyping(true);
    }
    stopTyping();
  };

  const onBackdropClicked = (e) => {
    if(e.target === e.currentTarget) { 
      e.preventDefault()
      hide()
    }
  } 

  return (
    <div className={`widget-backdrop index ${visibilityClass}`} onClick={onBackdropClicked}>
      <Audio />
      <div
        className={`hp-chat-widget-729 font-size-${fontSize} ${selectedTheme} ${visibilityClass}`}
      >
        <StatusContainer
          agent={agentName}
          showUndockButton={showUndockButton}
          staticUrl={staticUrl}
          minimizeOnClick={hide}
        />
        <MessageList isVisible={!minimized} onMessageRead={doMarkAsRead} />
        <InputBox
          onSubmit={handleOnSubmit}
          onChange={handleOnChange}
        />
        <div
          className={`spinner-container ${
            !minimized && connection !== "connected" ? "visible" : ""
          }`}
        >
          <div className="spinner"></div>
        </div>
      </div>
      <ChatButton
        buttonStyle={buttonStyle?.length > 0 ? buttonStyle : null}
        addClass={visibilityClass}
        onClick={show}
      />
    </div>
  );
};

Widget.defaultProps = {
  accountKey: "",
  buttonStyle: 'floating',
  departmentName: null,
  initialTheme: 'normal',
  staticUrl: '',
  showUndockButton: true,
  tags: [],
};

Widget.propTypes = {
  accountKey: PropTypes.string,
  buttonStyle: PropTypes.string,
  departmentName: PropTypes.string,
  initialTheme: PropTypes.string,
  staticUrl: PropTypes.string,
  showUndockButton: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),



};

export default Widget;
