import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectAgents,
  selectChats,
  selectLastRatingTimestamp,
} from "../features/widget/widgetSlice";
import ChatMessage from "./ChatMessage";
import QueuePosition from "./QueuePosition";
import RankButton from "./RankButton";
import SystemMessage from "./SystemMessage";

const MessageList = ({ isVisible, onMessageRead }) => {
  const messages = useSelector(selectChats);
  const agents = useSelector(selectAgents);
  const lastRatingTimestamp = useSelector(selectLastRatingTimestamp);
  const totalMessages = messages.length;
  const agentsTyping = Object.values(agents).filter((agent) => agent.typing);
  const someAgentTyping = agentsTyping.length > 0;
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    const target = messagesEndRef.current;
    if (target) {
      target.parentNode.scrollTop = target.offsetTop;
    }
  }

  useEffect(() => {
    scrollToBottom();

    if (isVisible && totalMessages > 0) {
      onMessageRead();
    }
  }, [totalMessages, isVisible, onMessageRead]);

  useEffect(() => {
    if (someAgentTyping) {
      scrollToBottom();
    }
  }, [someAgentTyping]);

  const renderByType = (msg, addClass) => {
    const agent = agents[msg.nick];
    switch (msg.type) {
      case "chat.file":
      case "chat.msg":
        return (
          <ChatMessage
            key={msg.type + msg.timestamp}
            message={msg}
            addClass={addClass}
            agent={agent}
          />
        );
      case "chat.errorMsg":
        return (
          <ChatMessage
            key={msg.type + msg.timestamp}
            message={msg}
            addClass={`${addClass} error`}
            agent={agent}
          />
        );
      case "chat.memberjoin":
      case "chat.memberleave":
      case "chat.rating":
      case "typing":
        return <SystemMessage key={msg.type + msg.timestamp} message={msg} />;
      case "chat.request.rating":
        return (
          <div
            key={msg.type + msg.timestamp}
            style={{
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <RankButton
              shouldDisplay={msg.timestamp === lastRatingTimestamp}
            />
          </div>
        );
      default:
        return (
          <div key={msg.type + msg.timestamp}>Unhandled message: {JSON.stringify(msg)}</div>
        );
    }
  };

  const renderTyping = () => {
    return agentsTyping.map((agent) => {
      return (
        <div key={+new Date()} className="chat-msg-container">
          <div className="chat-msg-wrapper">
            <div className="typing-indicator">
              <div className="typing-indicator-part">•</div>
              <div className="typing-indicator-part">•</div>
              <div className="typing-indicator-part">•</div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderAll = () => {
    let prev = null;
    return messages.map((message) => {
      let addClass = "",
        currentNick = message.nick,
        prevNick = prev && prev.nick;

      if (
        prev &&
        prev.type === message.type &&
        currentNick &&
        currentNick === prevNick
      )
        addClass = "sibling";

      prev = message;

      return renderByType(message, addClass);
    });
  };

  return (
    <div className="message-list-container">
      <div>{renderAll()}</div>
      <div ref={messagesEndRef} />
      {renderTyping()}
      <QueuePosition />
    </div>
  );
};

MessageList.defaultProps = {
  isVisible: false,
  isOffline: true,
  isChatting: false,
  onMessageRead: () => {},
};

MessageList.propTypes = {
  isVisible: PropTypes.bool,
  isOffline: PropTypes.bool,
  isChatting: PropTypes.bool,
  onMessageRead: PropTypes.func,
};

export default MessageList;
