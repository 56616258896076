import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Widget from "./components/Widget";
import "./styles/main.scss";
import * as serviceWorker from "./serviceWorker";
import {
  baseUrl,
  getScriptAttributes,
  getSessionId,
  isStaticContainer,
  setSessionCookie,
  staticUrl,
} from "./utils";

const widgetId = "hp-widget-component";


const staticContainer = isStaticContainer()

const {
  parentSelector,
  theme,
  buttonStyle,
  departmentName,
  tags,
  accountKey,
} = getScriptAttributes(staticContainer);

const parent = document.querySelector(parentSelector) || document.body;
setSessionCookie(staticContainer);
const sessionId = getSessionId(staticContainer);

let widget = document.getElementById(widgetId);

if (!widget) {
  widget = document.createElement("div");
  widget.id = widgetId;
  widget.defer = true;
  parent.appendChild(widget);
}

// Global variable to fix issue with imported sound files
global.hpWidgetUrl = baseUrl();
global.hpWidgetLoaded = true;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Widget
        initialTheme={theme}
        buttonStyle={buttonStyle}
        departmentName={departmentName}
        tags={tags}
        showUndockButton={!staticContainer}
        accountKey={accountKey}
        staticUrl={staticUrl(sessionId, accountKey)}
      />
    </Provider>
  </React.StrictMode>,
  widget
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
