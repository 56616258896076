import React  from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  setFontSize,
  selectFontSize,
} from "../features/widget/uiSlice";
import "../styles/components/FontSizeSelector.scss"

const FontSizeSelector = () => {
  const dispatch = useDispatch();
  const fontSize = useSelector(selectFontSize)
  
  return (
    <div className="font-size-container">
      <button
        className={`font-button small ${fontSize === 1 ? 'selected' : ''}`}
        onClick={() => dispatch(setFontSize(1))}
      >
        A
      </button>
      <button
        className={`font-button medium ${fontSize === 2 ? 'selected' : ''}`}
        onClick={() => dispatch(setFontSize(2))}
      >
        A
      </button>
      <button
        className={`font-button large ${fontSize === 3 ? 'selected' : ''}`}
        onClick={() => dispatch(setFontSize(3))}
      >
        A
      </button>
    </div>
  );
};

FontSizeSelector.defaultProps = {
  maxValue: 3,
};

FontSizeSelector.propTypes = {
  maxValue: PropTypes.number,
};

export default FontSizeSelector;
