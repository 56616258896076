import PropTypes from "prop-types";
import React, { useState } from "react";
import { sendChatComment } from "../lib/zdChat";
import Rank from "./Rank";

const RankComment = ({ onClose }) => {
  const [comment, setComment] = useState("");

  return (
    <div>
      <div>
        <p>Leave a comment (optional)</p>
        <textarea
          onChange={(e) => setComment(e.target.value)}
          placeholder="Type your message here..."
        />
      </div>
      <div className="submit-container">
        <button
          className="submit-btn"
          onClick={(e) => {
            if (comment.length > 0) {
              sendChatComment(comment)
                .then(() => {
                  onClose(e);
                })
                .catch((error) => {
                  console.log('Error sending comment: ', error.message);
                });
            } else {
              onClose(e)
            }
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const ChatRating = ({ onClose }) => {
  return (
    <div className="rating-modal">
      <div className="rating-container">
        <div className="rating-modal-content">
          <div className="rating-close">
            <button
              className="close-modal-btn"
              onClick={() => {
                onClose();
              }}
            >Close</button>
          </div>
          <div className="rating-row">
            <p className="rating-modal-title">
              How satisfied are you with the service you just received from the
              HP Live Expert?
            </p>
          </div>
          <div className="rating-row">
            <Rank />
          </div>
          <div className="rating-row">
            <RankComment onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

ChatRating.defaultProps = {
  agent: null,
  onClose: () => {},
};

ChatRating.propTypes = {
  agent: PropTypes.object,
  onClose: PropTypes.func,
};

export default ChatRating;
