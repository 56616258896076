import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import newMessageSound from "../audio/new-msg.mp3";
import { selectIsPlaying, setPlaying } from "../features/widget/uiSlice";
import { fixPath } from "../utils";

const AudioComponent = () => {
  const [audio] = useState(new Audio(fixPath(newMessageSound)));
  const isPlaying = useSelector(selectIsPlaying);
  const dispatch = useDispatch();

  useEffect(() => {
    isPlaying ? audio.play() : audio.pause();
  }, [isPlaying, audio]);
  useEffect(() => {
    audio.addEventListener("ended", () => dispatch(setPlaying(false)));
    return () => {
      audio.removeEventListener("ended", () => dispatch(setPlaying(false)));
    };
  }, [audio, dispatch]);
  return <></>;
};

export default AudioComponent;
