import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import {
  visitorMessageError,
  visitorSendMessage,
} from "../features/widget/widgetSlice";
import { sendChatMsg } from "../lib/zdChat";
import ChatMedia from "./ChatMedia";
import'../styles/components/Message.scss';
import Linkify from 'linkify-react';


const ChatMessage = ({ message, addClass }) => {
  const dispatch = useDispatch();

  const optionOnChange = (index) => {
    const msg = message.options[index];
    sendChatMsg(msg)
      .then(() => {
        dispatch(visitorSendMessage(msg));
      })
      .catch(() => {
        dispatch(visitorMessageError);
      });
  };

  const renderOptions = (options) => {
    if (!options || options.length <= 0) return;
    return (
      <div>
        {options.map((option, i) => {
          return (
            <div key={`hp_msg_${i}`}>
              <input
                type="radio"
                name="option"
                value={i}
                onChange={(e) => optionOnChange(e.currentTarget.value)}
              />{" "}
              {option}
            </div>
          );
        })}
      </div>
    );
  };

  const renderMessagePart = (msg) => {
    switch (msg.type) {
      case "chat.file":
        return <ChatMedia message={msg} />;
      default:
        return (
          <div className="chat-msg">
            <span><Linkify options={{target: '_blank'}}>{message.msg}</Linkify></span>
            {renderOptions(message.options)}
          </div>
        );
    }
  };

  return (
    <div className={`chat-msg-container ${message.member_type} ${addClass}`}>
      <div className="chat-msg-wrapper">{renderMessagePart(message)}</div>
    </div>
  );
};

ChatMessage.defaultProps = {
  message: {
    msg: "",
  },
  addClass: "",
};

ChatMessage.propTypes = {
  message: PropTypes.object,
  addClass: PropTypes.string,
};

export default ChatMessage;
