import { useSelector } from "react-redux";
import { selectCanRank, selectRating } from "../features/widget/widgetSlice";
import { sendChatRating } from "../lib/zdChat";
import '../styles/Rating.scss';

const rank = (current, newValue) => {
  if (current === newValue) {
    sendChatRating(null);
  } else {
    sendChatRating(newValue);
  }
};

const Rank = () => {
  const rating = useSelector(selectRating);
  const canRank = useSelector(selectCanRank);
  return (
    <>
      <button
        title="Thumb Up"
        className={`btn-thumb-up ${rating === "good" ? "selected" : ""}`}
        onClick={() => rank(rating, "good")}
        disabled={!canRank}
      >
        Thumb Up
      </button>
      <button
        title="Thumb Down"
        className={`btn-thumb-down ${rating === "bad" ? "selected" : ""}`}
        onClick={() => rank(rating, "bad")}
        disabled={!canRank}
      >
        Thumb Down
      </button>
    </>
  );
};

export default Rank;
