import PropTypes from "prop-types";
import React from "react";
import FloatingButton from "./FloatingButton";
import StaticButton from "./StaticButton";

const ChatButton = ({ addClass, buttonStyle, onClick }) => {
  if (buttonStyle === "static") {
    return <StaticButton addClass={addClass} onClick={onClick} />;
  }
  return <FloatingButton addClass={addClass} onClick={onClick} />;
};

ChatButton.defaultProps = {
  onClick: () => {},
  addClass: "",
  buttonStyle: "floating",
};

ChatButton.propTypes = {
  onClick: PropTypes.func,
  addClass: PropTypes.string,
  buttonStyle: PropTypes.oneOf(["static", "floating"]),
};

export default ChatButton;
