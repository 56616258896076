import PropTypes from "prop-types";
import React from "react";
import "../styles/Header.scss";
import Rank from "./Rank";

const undockWindow = (staticUrl) => {
  const newWindow = window.open(staticUrl, "_blank", "noopener,noreferrer,resizable,width=400,height=600");
  if (newWindow) newWindow.opener = null;
};

const StatusContainer = ({ staticUrl, showUndockButton, minimizeOnClick, agent }) => {
  return (
    <header className="chat-header">
      <div className="chat-brand">
        <span className="chat-logo">HP</span>
        <h2 className="chat-label">Chat with an HP Live Expert</h2>
      </div>
      { agent?.length > 0 && <span className="chat-expert-name">{agent}</span>}
      <div className="chat-navbar">
        <div className="chat-rating chat-bar-separator">
          <Rank />
        </div>
        {showUndockButton && (
          <div className="chat-bar-separator chat-detached">
            <button
              title="Detach Chat"
              className="btn-detached"
              onClick={() => undockWindow(staticUrl)}
            >
              Detach Chat
            </button>
          </div>
        )}
        <div className="chat-bar-separator chat-minimize">
          <button
            title="Minimize Chat"
            className="btn-minimize"
            onClick={minimizeOnClick}
          >
            Minimize Chat
          </button>
        </div>
      </div>
    </header>
  );
};

StatusContainer.defaultProps = {
  minimizeOnClick: () => {},
  staticUrl: "",
  showUndockButton: true,
  agentName: null,
};

StatusContainer.propTypes = {
  minimizeOnClick: PropTypes.func,
  staticUrl: PropTypes.string,
  showUndockButton: PropTypes.bool,
  agentName: PropTypes.string,
};

export default StatusContainer;
