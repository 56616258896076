const { NODE_ENV } = process.env;

const sessionCookie = "__zlcmid";
const scriptId = "hp-chat-widget";
const externalWidgetId = "__hp-static-widget-container__";

export const logger = console;

export function isAgent(nick) {
  return nick.startsWith("agent:");
}

export function isTrigger(nick) {
  return nick.startsWith("agent:trigger");
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function getUrlParam(name) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(name);
}

export function setSessionCookie(isStaticContainer) {
  if(isStaticContainer) {
    const sessionId = getUrlParam("sessionId");
    document.cookie = `${sessionCookie}=${sessionId};SameSite=Lax`;
  }
}

function getAccountKey(scriptTag) {
  let accountKey = null;
  if (isStaticContainer()) {
    accountKey = getUrlParam("accountKey");
  } else {
    accountKey = getAttribute(scriptTag, "zd-account-key")
    if(!accountKey) {
      const { REACT_APP_ACCOUNT_KEY } = process.env;
       accountKey = REACT_APP_ACCOUNT_KEY;
    }
  }
  return accountKey;
}

export function getSessionId(isStaticContainer) {
  let sessionId = null;
  if (isStaticContainer) {
    return sessionId;
  }
  return getCookie(sessionCookie);
}

export const baseUrl = () => {
  if(NODE_ENV !== 'production') {
    return '';
  }

  var script =
    document.currentScript ||
    document.querySelector('script[src*="hp-chat-widget.js"]');
  const urlSrc = script.src || "";
  const url = urlSrc.split("/");
  url.pop();
  return url.join("/");
};

export const fixPath = (path) => {
  return `${global.hpWidgetUrl}${path}`
}

// Beware that if you cahnge the name of the undocked widget
// You'll need to also rename the file on public folder
export function staticUrl(sessionId, accountKey) {
  return `${baseUrl()}/undocked-widget.html?accountKey=${accountKey}&sessionId=${sessionId}`;
}

function getAttribute(scriptTag, attrName) {
  return scriptTag?.getAttribute(`data-${attrName}`) || "";
}

export function getScriptAttributes() {
  const scriptTag = document.getElementById(scriptId);
  const tagsString = getAttribute(scriptTag, "tags");
  const tags =
    tagsString.length > 0 ? tagsString.split(",").map((i) => i.trim()) : [];
  return {
    accountKey: getAccountKey(scriptTag),
    parentSelector: scriptTag?.getAttribute("data-parent-selector"),
    theme: isStaticContainer() ? 'expanded' : getAttribute(scriptTag, "widget-theme").toLowerCase(),
    buttonStyle: getAttribute(scriptTag, "button-style").toLowerCase(),
    departmentName: getAttribute(scriptTag, "department-name").toLowerCase(),
    tags,
  };
}

export function isStaticContainer() {
  return document.getElementById(externalWidgetId);
}