import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import "../../styles/components/StaticButton.scss"
import { selectMinimized } from "../../features/widget/uiSlice";
import { selectIsChatting, selectUnreadCount } from "../../features/widget/widgetSlice";
import MinimizedButton from "./MinimizedButton";

const StaticButton = ({ addClass, onClick, disabled }) => {
  const isChatting = useSelector(selectIsChatting);
  const isMinimized = useSelector(selectMinimized);
  const unreadCount = useSelector(selectUnreadCount);

  return (
    <div className="hp-chat-button-widget-729">
      <button className={`chat-static-button chat-static-button-logo ${addClass}`} onClick={onClick} disabled={disabled}>
        <span className="chat-static-button__text">
          <span className="title">Have a question about this product?</span>
          <span className="subtitle">Chat with an HP Live Expert</span>
        </span>
      </button>
      {isMinimized && isChatting && <MinimizedButton addClass={addClass} onClick={onClick} unreadCount={unreadCount} />}
    </div>
)};

StaticButton.defaultProps = {
  onClick: () => {},
  addClass: "",
  disabled: false,
};

StaticButton.propTypes = {
  onClick: PropTypes.func,
  addClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StaticButton;
