import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/ChatInput.scss';

const ChatInput = ({ inputRef, onChange }) => {
  return (
    <>
        <label for="chat-input-id" className='visually-hidden'>Type your message here...</label>
        <input
          id="chat-input-id"
          className="chat-input"
          placeholder="Type your message here..."
          ref={inputRef}
          onChange={onChange}
        />
    </>
  );
};

ChatInput.defaultProps = {
  onChange: () => {},
};

ChatInput.propTypes = {
  onChange: PropTypes.func,
};

export default ChatInput;
