import { camelCase } from "lodash";
import * as slice from "../features/widget/widgetSlice";
import zChat from "../lib/web-sdk";
import { logger } from "../utils";

const events = [
  "account_status",
  "connection_update",
  "department_update",
  "visitor_update",
  "agent_update",
  "chat",
  "error",
];

const initEvents = (dispatch) => {
  events.forEach((evt) => {
    zChat.on(evt, (data) => {
      if (evt === "chat") {
        const chatEvent = camelCase(data.type);
        dispatch(slice[chatEvent](data));
      } else {
        dispatch(slice[camelCase(evt)](data));
      }
    });
  });
};

export const EMAIL_REGEX = zChat.EMAIL_REGEX;

const zendeskInit = async (accountKey) => {
  const connectionStatus = new Promise((resolve, reject) => {
    zChat.on("connection_update", (value) => {
      if (value === "connected") {
        return resolve("Connected");
      }
      if (value === "closed") {
        return reject("Connection Closed");
      }
    });
  });

  zChat.init({ account_key: accountKey });
  return connectionStatus;
};

export const initChatSDK = async ({
  accountKey,
  dispatch,
  departmentName,
  tags,
}) => {
  if (!accountKey || accountKey.length === 0) {
    logger.error("*** HP Chat Widget Key not found, please configure it via the data-zd-account-key attribute ***");
    return null;
  }
  const connectionStatus = await zChat.getConnectionStatus();
  if (connectionStatus === "closed") {
    initEvents(dispatch);
    try {
      await zendeskInit(accountKey);
    } catch(error) {
      logger.error("*** HP Chat Widget falied to initialize, please check data-zd-account-key attribute ***");
      return null;
    }
    if (departmentName && departmentName.length > 0) {
      try {
        await setVisitorDepartment(departmentName);
      } catch (error) {
        logger.error(
          `Error Setting department ${departmentName} Ignoring: ${error.message}`
        );
      }
    }
    if (tags.length > 1) {
      try {
        await addTags(tags);
      } catch (error) {
        logger.error(`Error Setting tags ${tags} Ignoring: ${error.message}`);
      }
    }
  }
};

export const reconnect = async (status) => {
  if(status === 'closed') {
    zChat.reconnect();
  }
}

export const sendTyping = async (typing) => {
  zChat.sendTyping(typing);
};

export const markAsRead = () => {
  zChat.markAsRead();
};

const promisify = async (zdFunction, param) => {
  return new Promise((resolve, reject) => {
    const handleError = (err) => {
      reject(err);
    }
    zChat.on('error', handleError);
    zdFunction(param, (error, data) => {
      if(error) {
        reject(error);
      } else {
        resolve(data)
      }
    })
    zChat.un('error', handleError);
  })
}

export const sendChatRating = async (rating) => {
  try {
    await promisify(zChat.sendChatRating, rating);
  } catch(error) {
    logger.error('Error Sending rating: ', error.message)
  }
};

export const sendChatComment = async (comment) => {
  return promisify(zChat.sendChatComment, comment);
}

export const sendChatMsg = async (msg) => {
  return promisify(zChat.sendChatMsg, msg);
};

export const setVisitorDepartment = async (departmentName) => {
  const departments = zChat.getAllDepartments();
  const department = departments.find((dep) => dep.name.toLowerCase() === departmentName.toLowerCase());
  if(department) {
    await promisify(zChat.setVisitorDefaultDepartment, department.id);
  } else {
    throw new Error(`Department ${departmentName} not found`);
  }
  return null;
};

export const addTags = async (tags) => {
  return promisify(zChat.addTags, tags);
};

export const setVisitorInfo = async (info) => {
  return promisify(zChat.setVisitorInfo, info);
};

export const endChat = async () => {
  return promisify(zChat.endChat, {});
};

export const sendEmailTranscript = (email) => {
  return promisify(zChat.sendEmailTranscript, email);
};
