import PropTypes from 'prop-types';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowRankDialog } from '../features/widget/uiSlice';
import { selectAgentLeftChat, selectCanRank } from "../features/widget/widgetSlice";
import "../styles/components/RankButton.scss";

const RankButton = ({ shouldDisplay }) => {
  const dispatch = useDispatch();
  const canRank = useSelector(selectCanRank);
  const chatFinished = useSelector(selectAgentLeftChat);

  if (!shouldDisplay) {
      return null;
  }

  const handleRateChat = () => {
    dispatch(setShowRankDialog({chatFinished, show: true }));
  }

  return (
    <button
      disabled={!canRank}
      className="btn rank-button"
      onClick={() => handleRateChat()}
    >
      Rate this chat
    </button>
  )
};

RankButton.defaultProps = {
  shouldDisplay: true,
}

RankButton.propTypes = {
  shouldDisplay: PropTypes.bool,
}

export default RankButton;