import React from "react";
import "../styles/components/Button.scss";

const Button = ({ className, iconClass, label }) => {
  if (iconClass) {
    return <button className={`${className} text-hide btn-icon ${iconClass}`}>{label}</button>;
  }

  return <button className={className}>{label}</button>;
};

export default Button;
