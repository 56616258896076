import React from "react";
import PropTypes from "prop-types";

const ChatImage = ({ message }) => (
  <a
    href={message.attachment.url}
    target="_blank"
    rel="noreferrer"
    className="chat-img-container"
  >
    <div
      className="chat-img"
      style={{
        backgroundImage: `url(${message.attachment.url})`,
      }}
    />
  </a>
);

ChatImage.defaultProps = {
  message: {
    url: '',
  },
};

ChatImage.propTypes = {
  message: PropTypes.object,
};

export default ChatImage;
