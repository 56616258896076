import { createSlice } from "@reduxjs/toolkit";
import { isAgent, isTrigger } from "../../utils";

const initialState = {
  connection: "closed",
  account_status: "offline",
  departments: {},
  visitor: {},
  agents: {},
  is_chatting: false,
  chat_finished_by_agent: false,
  chats: {},
  last_timestamp: 0,
  last_read_timestamp: 0,
  last_rating_request_timestamp: 0,
  rating: null,
  queue_position: 0,
};

const handleMessage = (state, action) => {
  let { nick, timestamp, display_name: displayName } = action.payload;
  state.last_timestamp = timestamp;
  if (isTrigger(nick)) {
    nick = `agent:trigger:${displayName}`;
  }
  state.chats[timestamp] = {
    ...action.payload,
    nick,
    member_type: isAgent(nick) ? "agent" : "visitor",
  };
}

export const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    agentUpdate: (state, action) => {
      const nick = action.payload.nick;
      const prevAgent = state.agents[nick];
      state.agents[nick] = {
        typing: prevAgent.typing || false,
        ...action.payload,
      };
    },
    connectionUpdate: (state, action) => {
      state.connection = action.payload;
    },
    accountStatus: (state, action) => {
      state.account_status = action.payload;
    },
    depertmentUpdate: (state, action) => {
      state.departments[action.payload.id] = action.payload;
    },
    chatFinished: (state) => {
      state.is_chatting = false;
    },
    chatMemberjoin: (state, action) => {
      const { nick, timestamp } = action.payload;
      state.last_timestamp = timestamp;
      state.chat_finished_by_agent = false;
      if (isAgent(nick)) {
        if (!state.agents[nick]) {
          state.agents[nick] = {};
        }
        state.agents[nick].nick = nick;
      } else {
        state.visitor.nick = nick;
        state.is_chatting = true;
      }
      state.chats[timestamp] = action.payload;
    },
    chatMemberleave: (state, action) => {
      const { nick, timestamp } = action.payload;
      state.last_timestamp = timestamp;
      delete state.agents[nick];
      if (!isAgent(nick)) {
        state.is_chatting = false;
      } else {
        state.chat_finished_by_agent = Object.keys(state.agents).every(a => !isAgent(a));
      }
      
      state.chats[timestamp] = action.payload;
    },
    chatQueuePosition: (state, action) => {
      state.queue_position = action.payload.queue_position;
    },
    chatRequestRating: (state, action) => {
      const { timestamp } = action.payload;
      state.last_timestamp = timestamp;
      state.chats[timestamp] = action.payload;
      state.last_rating_request_timestamp = timestamp;
    },
    chatRating: (state, action) => {
      const { timestamp, new_rating } = action.payload;
      state.last_timestamp = timestamp;
      state.chats[timestamp] = action.payload;
      state.rating = new_rating;
    },
    chatFile: handleMessage,
    chatMsg: handleMessage,
    lastRead: (state, action) => {
      state.last_read_timestamp = action.payload.timestamp;
    },
    typing: (state, action) => {
      const { nick, display_name: displayName, typing } = action.payload;
      let agent = state.agents[nick];
      agent.typing = typing;
      if (isTrigger(nick)) {
        agent.nick = `agent:trigger:${displayName}`;
        agent.display_name = displayName;
      }
      state.agents[nick] = agent;
    },
    visitorUpdate: (state, action) => {
      state.visitor = { ...state.visitor, ...action.payload };
    },
    visitorSendMessage: (state, action) => {
      state.last_timestamp += 1;
      let nick = state.visitor.nick || "visitor:";
      // Ensure that triggers are uniquely identified by their display names
      if (isTrigger(nick)) {
        nick = `agent:trigger:${state.visitor.display_name}`;
      }
      state.chats[state.last_timestamp] = {
        msg: action.payload,
        display_name: state.visitor.display_name,
        nick,
        type: "chat.msg",
        timestamp: state.last_timestamp,
        source: "local",
        member_type: isAgent(nick) ? "agent" : "visitor",
      };
    },
    visitorMessageError: (state, action) => {
      state.last_timestamp += 1;
      let nick = state.visitor.nick || "visitor:";
      // Ensure that triggers are uniquely identified by their display names
      if (isTrigger(nick)) {
        nick = `agent:trigger:${state.visitor.display_name}`;
      }
      state.chats[state.last_timestamp] = {
        msg: action.payload,
        display_name: state.visitor.display_name,
        nick,
        type: "chat.errorMsg",
        timestamp: state.last_timestamp,
        source: "local",
        member_type: isAgent(nick) ? "agent" : "visitor",
      };
    },
  },
});

//Actions
export const {
  accountStatus,
  agentUpdate,
  chatFinished,
  chatMemberjoin,
  chatMemberleave,
  chatMsg,
  chatFile,
  chatQueuePosition,
  chatRating,
  chatRequestRating,
  connectionUpdate,
  depertmentUpdate,
  lastRead,
  typing,
  visitorSendMessage,
  visitorMessageError,
  visitorUpdate,
} = widgetSlice.actions;

//Selectors
export const selectAccountStatus = (state) => state.widget.account_status;
export const selectIsChatting = (state) => state.widget.is_chatting;
export const selectCanRank = (state) => state.widget.is_chatting && state.widget.account_status === 'online';
export const selectAgents = (state) => state.widget.agents;
export const selectConnection = (state) => state.widget.connection;
export const selectQueuePosition = (state) => state.widget.queue_position;
export const selectAgentLeftChat = (state) => state.widget.chat_finished_by_agent;
export const selectChats = (state) => {
  const chats = state.widget.chats;
  return Object.keys(chats)
    .sort()
    .map((message) => chats[message]);
};
export const selectLastRatingTimestamp = (state) =>
  state.widget.last_rating_request_timestamp;

export const selectHasRating = (state) => state.widget.rating !== null;
export const selectRating = (state) => state.widget.rating;

export const selectAgentName = (state) => {
  return Object.values(state.widget.agents)[0]?.display_name;
}


export const selectOffline = (state) =>
  state.widget.account_status === "offline" && !state.widget.is_chatting;

export const selectUnreadCount = (state) => {
  const lastRead = state.widget.last_read_timestamp;
  const chats = state.widget.chats;
  const msgTimestamps = Object.keys(chats).filter(
    (chat) => chats[chat].type === "chat.msg"
  );
  return msgTimestamps.reduce((unreads, timestamp) => {
    if (timestamp > lastRead) {
      return unreads + 1;
    }
    return unreads;
  }, 0);
};

export default widgetSlice.reducer;
