import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { chatFile, chatMsg, connectionUpdate } from "./widgetSlice";

const EXPANDED = "expanded";
const VALID_THEMES = ["normal", "docked", EXPANDED];
const SMALL_FONT_SIZE = 1;

const initialState = {
  theme: "normal",
  fontSize: SMALL_FONT_SIZE,
  soundOn: true,
  isPlaying: false,
  appConnected: false,
  minimized: true,
  showEndChatDialog: false,
  showRankDialog: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    decreaseFontSize: (state) => {
      if (state.fontSize > 0) {
        state.fontSize -= 1;
      }
    },
    setTheme: (state, action) => {
      if (VALID_THEMES.includes(action.payload)) {
        state.theme = action.payload;
      }
      if (action.payload === EXPANDED) {
        state.minimized = false;
      }
    },
    setPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    toggleSound: (state) => {
      state.soundOn = !state.soundOn;
    },
    minimize: (state, action) => {
      state.minimized = action.payload;
    },
    setFontSize: (state, action) => {
      state.fontSize = action.payload;
    },
    setShowEndChatDialog: (state, action) => {
      state.showEndChatDialog = action.payload;
    },
    setShowRankDialog: (state, action) => {
      const {chatFinished, show } = action.payload;
      if(chatFinished) {
        state.showRankDialog = show;
        state.showEndChatDialog = false;
      } else {
        state.showEndChatDialog = show;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(connectionUpdate, (state, action) => {
      state.appConnected = action.payload === "connected";
      if (action.payload === "closed") {
        state.minimized = true;
      }
    });
    builder.addMatcher(isAnyOf(chatFile, chatMsg), (state) => {
      if (state.soundOn && state.appConnected) {
        state.isPlaying = true;
      }
    });
  },
});

//Actions
export const {
  increaseFontSize,
  decreaseFontSize,
  setTheme,
  setPlaying,
  toggleSound,
  minimize,
  setFontSize,
  setShowEndChatDialog,
  setShowRankDialog,
} = uiSlice.actions;

//Selectors
export const selectFontSize = (state) => state.ui.fontSize;
export const selectTheme = (state) => state.ui.theme;
export const selectIsPlaying = (state) => state.ui.isPlaying;
export const selectSoundOn = (state) => state.ui.soundOn;
export const selectMinimized = (state) => state.ui.minimized;
export const selectEndChatDialog = (state) => state.ui.showEndChatDialog;
export const selectRankDialog = (state) => state.ui.showRankDialog;

export default uiSlice.reducer;
