import React from "react";
import PropTypes from "prop-types";

const MinimizedButton = ({ addClass, onClick, unreadCount }) => {
  const UnreadMessageCount = () => (
    <span className="unread-msg">
      {unreadCount} {`New message${unreadCount > 1 ? "(s)" : ""}`}
    </span>
  );

  return (
    <div className="hp-chat-button-widget-729">
      <div className="chat-minimized-container">
        <button
          className={`chat-button chat-minimized ${addClass}`}
          onClick={onClick}
        >
          {unreadCount > 0 && <UnreadMessageCount />}
          Chat with an HP Live Expert <span className="separator"></span>
        </button>
      </div>
    </div>
  );
};

MinimizedButton.defaultProps = {
  onClick: () => {},
  addClass: "",
  unreadCount: 0,
};

MinimizedButton.propTypes = {
  onClick: PropTypes.func,
  addClass: PropTypes.string,
  unreadCount: PropTypes.number,
};

export default MinimizedButton;
