import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectIsChatting,
  selectUnreadCount,
} from "../../features/widget/widgetSlice";
import { selectMinimized } from "../../features/widget/uiSlice";
import "../../styles/components/FloatingButton.scss";
import MinimizedButton from "./MinimizedButton";

const FloatingButton = ({ addClass, onClick }) => {
  const isChatting = useSelector(selectIsChatting);
  const isMinimized = useSelector(selectMinimized);
  const unreadCount = useSelector(selectUnreadCount);

  if (isMinimized && isChatting)
    return (
      <MinimizedButton
        addClass={addClass}
        onClick={onClick}
        unreadCount={unreadCount}
      />
    );

  return (
    <div className="hp-chat-button-widget-729">
      <button
        className={`chat-button chat-floating-button ${addClass}`}
        onClick={onClick}
      >
        Have a question? <br /> Chat with an HP Live Expert
      </button>
    </div>
  );
};

FloatingButton.defaultProps = {
  onClick: () => {},
  addClass: "",
};

FloatingButton.propTypes = {
  onClick: PropTypes.func,
  addClass: PropTypes.string,
};

export default FloatingButton;
