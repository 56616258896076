import PropTypes from "prop-types";
import React from "react";
import { isAgent } from "../utils";
import RankButton from "./RankButton";

const convertToSentenceCase = (str) => str[0].toUpperCase() + str.slice(1);
const getMessageByType = (msg) => {
  
  const LeftChatMsg = () => {
    if (isAgent(msg.nick)) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {`${msg.display_name} has left the chat`}
          <RankButton />
        </div>
        )
    }
    return `${msg.display_name} has left the chat`;
  }

  switch (msg.type) {
    case "chat.memberjoin":
      return `${msg.display_name} has joined the chat`;
    case "chat.memberleave":
      return <LeftChatMsg />;
    case "chat.rating":
      if (!msg.new_rating) {
        return "You have removed the chat rating";
      } else {
        const rating = convertToSentenceCase(msg.new_rating);
        return `You have rated the chat service ${rating}`;
      }
    default:
      return JSON.stringify(msg);
  }

};

const SystemMessage = ({ message }) => (
  <div className="system-msg-container">
    <span className="system-msg">{getMessageByType(message)}</span>
  </div>
);

SystemMessage.defaultProps = {
  message: {
    msg: "",
  },
};

SystemMessage.propTypes = {
  message: PropTypes.object,
};

export default SystemMessage;
